import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

class AboutSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            AboutList: [{
                "icon": "mdi mdi-lightbulb",
                "title": "Identify And Remediate Misconfigurations",
                "content": "Automatically identify and remediate any vulnerabilities such as exposed sensitive data, lack of data encryption, excessive account permissions, and more.",
            }, {
                "icon": "mdi mdi-projector-screen",
                "title": "Be Fully Compliant With Regulatory Frameworks",
                "content": "Align with best practice security standards such as CIS Benchmarks and conform to regulatory frameworks in particular GDPR, PCI and NIST.",
            }, {
                "icon": "mdi mdi-nature",
                "title": "Achieve Continuous Visibility",
                "content": "Achieve peace of mind with continuous  monitoring of all your cloud accounts from a consolidated dashboard.",
            },
            ]
        };
    }

    render() {

        return (
            <React.Fragment>
                <section className="section bg-about bg-light-about bg-light" id="about">
                    <div className="container">
                        <Row>
                            <Col lg="4">
                                <div className="title-heading mb-5">
                                    <h3 className="text-dark mb-1 font-weight-light text-uppercase">About</h3>
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            {this.state.AboutList.map((about, index) => {
                                return <Col lg="4" key={index}>
                                            <div className="about-box about-light text-center p-3">
                                                <div className="about-icon mb-4">
                                                    <i className={about.icon}></i>
                                                </div>
                                                <h4 className="font-weight-light"><Link to="#" className="text-dark">{about.title}</Link></h4>
                                                <p className="text-muted f-14">{about.content}</p>
                                            </div>
                                        </Col>
                              })}
                         </Row>

                        <Row className="align-items-center mt-5">
                            <Col md="6">
                                <div className="about-desc">
                                    <h3 className="text-dark mb-3 font-weight-light">Cloud Security Posture Management (CSPM)</h3>
                                    <p className="text-muted f-15">Continuously monitor cloud accounts, services and assets to identify weaknesses that lead to loss of business.  Receive regular notifications of cloud misconfigurations that require immediate remediation.  PrymeCloud is the solution to keep your business safe at all times.  Securing Azure and AWS has never been easier.</p>
                                    <div className="about-by">
                                        <Link to="#" className="btn btn-custom-green mr-4">Start Automation</Link>
                                    </div>
                                </div>
                            </Col>

                            <Col md="6">
                                <div className="about-img light-img position-relative p-4">
                                    <img src="images/about-img.jpg" alt="" className="img-fluid mx-auto d-block" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default AboutSection;