import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import RBCarousel from "react-bootstrap-carousel";

const styles = { height: 400, width: "50%" };

class ClientSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            autoplay: true
        };
    }
    onSelect = (active, direction) => {
        console.log(`active=${active} && direction=${direction}`);
    };
    visiableOnSelect = active => {
        console.log(`visiable onSelect active=${active}`);
    };
    slideNext = () => {
        this.slider.slideNext();
    };
    slidePrev = () => {
        this.slider.slidePrev();
    };
    goToSlide = () => {
        this.slider.goToSlide(4);
    };
    autoplay = () => {
        this.setState({ autoplay: !this.state.autoplay });
    };
    _changeIcon = () => {
        let { leftIcon, rightIcon } = this.state;
        leftIcon = leftIcon ? undefined : <span className="fa fa-glass" />;
        rightIcon = rightIcon ? undefined : <span className="fa fa-music" />;
        this.setState({ leftIcon, rightIcon });
    };
    render() {
        return (
            <React.Fragment>

                <section className="section bg-clients" id="faqs">
                    <div className="bg-overlay"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="title-heading mb-5">
                                    <h3 className="text-white mb-1 font-weight-light text-uppercase">Why Use PrymeCloud?</h3>
                                    <p className="text-white-70 font-weight-light mb-0 f-15">Monitor your cloud environments and assets. Cloud security is key. Don’t be the next victim!</p>
                                    <div className="title-border-color position-relative"></div>
                                </div>
                            </div>
                        </div>

                        <Row>
                            <Col span={12} style={{ marginTop: 20 }}>
                                <RBCarousel
                                    version={4}
                                    autoplay={this.state.autoplay}
                                    pauseOnVisibility={true}
                                    onSelect={this.visiableOnSelect}
                                    slideshowSpeed={2000}
                                >
                                    <div className="item">
                                        <Row>
                                            <Col lg="6">
                                                <div className="testi-content">
                                                    <div className="testi-box mt-4">
                                                        <h4 className="text-white mb-3 font-weight-light"> </h4>
                                                        <p className="text-white-70 font-weight-light mb-0 f-15">Over 99% of cloud breaches will have a root cause of customer misconfiguration or mistakes by 2025</p>
                                                        <div className="quote-img">
                                                            <img src="images/quote-img.png" alt="" className="img-fluid" />
                                                        </div>
                                                    </div>
                                                    <div className="mt-2">
                                                        <div className="float-right ml-3">
                                                            {/* <div className="client-img"> */}
                                                            {/*     <img src="images/clients/img-1.jpg" alt="" className="img-fluid rounded-circle" /> */}
                                                            {/* </div> */}
                                                        </div>
                                                        <div className="clients-name text-right pt-3">
                                                            <h6 className="text-white font-weight-normal position-relative f-17 mb-0"><span className="after-border"></span>Gartner</h6>
                                                            {/* <p className="text-white-70 f-13 mb-0">UI/UX Designer</p> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg="6">
                                                <div className="testi-content">
                                                    <div className="testi-box mt-4">
                                                        <h4 className="text-white mb-3 font-weight-light"> </h4>
                                                        <p className="text-white-70 font-weight-light mb-0 f-15">19% of data breaches happen because IT teams fail to properly protect the assets found within their cloud infrastructure.</p>
                                                        <div className="quote-img">
                                                            <img src="images/quote-img.png" alt="" className="img-fluid" />
                                                        </div>
                                                    </div>
                                                    <div className="mt-2">
                                                        <div className="float-right ml-3">
                                                            {/* <div className="client-img"> */}
                                                            {/*     <img src="images/clients/img-1.jpg" alt="" className="img-fluid rounded-circle" /> */}
                                                            {/* </div> */}
                                                        </div>
                                                        <div className="clients-name text-right pt-3">
                                                            <h6 className="text-white font-weight-normal position-relative f-17 mb-0"><span className="after-border"></span>IBM</h6>
                                                            {/* <p className="text-white-70 f-13 mb-0">UI/UX Designer</p> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className="item">
                                        <Row>
                                            <Col lg="6">
                                                <div className="testi-content">
                                                    <div className="testi-box mt-4">
                                                        <h4 className="text-white mb-3 font-weight-light"> </h4>
                                                        <p className="text-white-70 font-weight-light mb-0 f-15">A misconfigured AWS S3 Bucket, owned by online marketing firm and data analytics company Alteryx, exposed 36GB worth of data to the public, including mortgage and consumer information.</p>
                                                        <div className="quote-img">
                                                            <img src="images/quote-img.png" alt="" className="img-fluid" />
                                                        </div>
                                                    </div>
                                                    <div className="mt-2">
                                                        <div className="float-right ml-3">
                                                            {/* <div className="client-img"> */}
                                                            {/*     <img src="images/clients/img-3.jpg" alt="" className="img-fluid rounded-circle" /> */}
                                                            {/* </div> */}
                                                        </div>
                                                        <div className="clients-name text-right pt-3">
                                                            {/* <h6 className="text-white font-weight-normal position-relative f-17 mb-0"><span className="after-border"></span> Mary Cantu</h6> */}
                                                            {/* <p className="text-white-70 f-13 mb-0">PHP Developer</p> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg="6">
                                                <div className="testi-content">
                                                    <div className="testi-box mt-4">
                                                        <h4 className="text-white mb-3 font-weight-light"> </h4>
                                                        <p className="text-white-70 font-weight-light mb-0 f-15">An internal non-password-protected database, at Microsoft, led to 250 million customer support records being accidentally exposed on the Internet.</p>
                                                        <div className="quote-img">
                                                            <img src="images/quote-img.png" alt="" className="img-fluid" />
                                                        </div>
                                                    </div>
                                                    <div className="mt-2">
                                                        <div className="float-right ml-3">
                                                            {/* <div className="client-img"> */}
                                                            {/*     <img src="images/clients/img-4.jpg" alt="" className="img-fluid rounded-circle" /> */}
                                                            {/* </div> */}
                                                        </div>
                                                        <div className="clients-name text-right pt-3">
                                                            {/* <h6 className="text-white font-weight-normal position-relative f-17 mb-0"><span className="after-border"></span> Rodney Grey</h6> */}
                                                            {/* <p className="text-white-70 f-13 mb-0">Graphic Designer</p> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className="item">
                                        <Row>
                                            <Col lg="6">
                                                <div className="testi-content">
                                                    <div className="testi-box mt-4">
                                                        <h4 className="text-white mb-3 font-weight-light"> </h4>
                                                        <p className="text-white-70 font-weight-light mb-0 f-15">Blur password manager exposes 2.4M users on a misconfigured AWS cloud instance.  The exposed data contained usernames, emails, password hints and the last and second-to-last IP addresses that were used to log in to Blur.</p>
                                                        <div className="quote-img">
                                                            <img src="images/quote-img.png" alt="" className="img-fluid" />
                                                        </div>
                                                    </div>
                                                    <div className="mt-2">
                                                        <div className="float-right ml-3">
                                                            {/* <div className="client-img"> */}
                                                            {/*     <img src="images/clients/img-5.jpg" alt="" className="img-fluid rounded-circle" /> */}
                                                            {/* </div> */}
                                                        </div>
                                                        <div className="clients-name text-right pt-3">
                                                            {/* <h6 className="text-white font-weight-normal position-relative f-17 mb-0"><span className="after-border"></span> Shana Esposito</h6> */}
                                                            {/* <p className="text-white-70 f-13 mb-0">Web Developer</p> */}
                                                        </div>
                                                    </div>
                                                </div>

                                            </Col>
                                            <Col lg="6">
                                                <div className="testi-content">
                                                    <div className="testi-box mt-4">
                                                        <h4 className="text-white mb-3 font-weight-light"> </h4>
                                                        <p className="text-white-70 font-weight-light mb-0 f-15">Through 2024, organizations implementing a CSPM offering and extending this into development will reduce cloud-related security incidents due to misconfiguration by 80%.</p>
                                                        <div className="quote-img">
                                                            <img src="images/quote-img.png" alt="" className="img-fluid" />
                                                        </div>
                                                    </div>
                                                    <div className="mt-2">
                                                        <div className="float-right ml-3">
                                                            {/* <div className="client-img"> */}
                                                            {/*     <img src="images/clients/img-6.jpg" alt="" className="img-fluid rounded-circle" /> */}
                                                            {/* </div> */}
                                                        </div>
                                                        <div className="clients-name text-right pt-3">
                                                            <h6 className="text-white font-weight-normal position-relative f-17 mb-0"><span className="after-border"></span>Gartner</h6>
                                                            {/* <p className="text-white-70 f-13 mb-0">UI/UX Designer</p> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </RBCarousel>
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default ClientSection;