import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

class FeatureSection extends React.Component {
    render() {

        return (
            <React.Fragment>
                <section className="section bg-features bg-light" id="features">
                    <div className="container">
                        <Row>
                            <Col className="col-lg-12">
                                <div className="title-heading mb-5">
                                    <h3 className="text-dark mb-1 font-weight-light text-uppercase">Our Features</h3>
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            <Col lg="6">
                                <div className="features-content">
                                    <div className="features-icon">
                                        <i className="pe-7s-science text-primary"></i>
                                    </div>
                                    <h4 className="font-weight-normal text-dark mb-3 mt-4">Automated Continuous Audit</h4>
                                    <p className="text-muted f-14">Automated checks of all assets and environments, on AWS and Azure, against hundreds of up-to-date rules, to discover any weaknesses in your cloud infrastructure. PrymeCloud provides rules that are in sync with both AWS and Azure’s updates, changes and new service releases.  Manage AWS and Azure security with our integrated smart rules engine checks that includes elements such as:
                                        <ul>
                                            <li>
                                                Unencrypted data in transit and at rest
                                            </li>
                                            <li>
                                                Weak password policies
                                            </li>
                                            <li>
                                                Cloud Storage publicly available to the internet
                                            </li>
                                            <li>
                                                Lack of logging and monitoring in place
                                            </li>
                                            <li>
                                                Overly-permissive roles
                                            </li>
                                            <li>
                                                Missing two-factor or multi-factor authentication
                                            </li>
                                        </ul>
                                    </p>
                                    {/* <p className="mb-0 text-uppercase f-13"><Link to="/" className="text-primary">Audit Now <span className="right-arrow ml-1">&#x21FE;</span></Link></p> */}
                                    <Link to="#" className="btn btn-custom-green mr-4">Audit Now</Link>
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="features-img mt-32">
                                    <img src="images/features-img/img-1.png" alt="" className="img-fluid mx-auto d-block" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>

                <section className="section bg-features">
                    <div className="container">
                        <Row className="align-items-center">
                            <Col lg="6">
                                <div className="features-img">
                                    <img src="images/features-img/img-2.png" alt="" className="img-fluid mx-auto d-block" />
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="features-content mt-32">
                                    <div className="features-icon">
                                        <i className="pe-7s-shuffle text-primary"></i>
                                    </div>
                                    <h4 className="font-weight-normal text-dark mb-3 mt-4">Manage And Mitigate Risk</h4>
                                    <p className="text-muted f-14">PrymeCloud offers an integrated centralised dashboard illustrating cyber security issues in your cloud environment together with actionable recommendations. Prioritise risk management efforts according to your requirements.  PrymeCloud’s dashboard provides on spot statistics and insights based on highlighted vulnerabilities’ criticality.  Manage AWS and Azure security including roles, s3 buckets, endpoint services, Lambda functions, VNets, and blob storage amongst others.</p>
                                    {/* <p className="mb-0 text-uppercase f-13"><Link to="/" className="text-primary">Manage Risk Now <span className="right-arrow ml-1">&#x21FE;</span></Link></p> */}
                                    <Link to="#" className="btn btn-custom-green mr-4">Manage Risk Now </Link>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>

                <section className="section bg-features bg-light">
                    <div className="container">
                        <Row className="align-items-center">
                            <Col lg="6">
                                <div className="features-content">
                                    <div className="features-icon">
                                        <i className="pe-7s-display1 text-primary"></i>
                                    </div>
                                    <h4 className="font-weight-normal text-dark mb-3 mt-4">Keep On Top Of Vulnerabilities</h4>
                                    <p className="text-muted f-14">PrymeCloud provides a comprehensive report following automated scans and alerts users of any detected risks.  Both technical and non-technical users can re-evaluate and reassess their cloud security and environment whenever they like by triggering the ad hoc scan functionality.</p>
                                    {/* <p className="mb-0 text-uppercase f-13"><Link to="/" className="text-primary">Check For Vulnerabilities Now <span className="right-arrow ml-1">&#x21FE;</span></Link></p> */}
                                    <Link to="#" className="btn btn-custom-green mr-4">Check For Vulnerabilities Now </Link>
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="features-img mt-40">
                                    <img src="images/features-img/img-3.png" alt="" className="img-fluid mx-auto d-block" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default FeatureSection;