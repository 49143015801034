import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';


class ServiceSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ServiceList: [{
                "icon": "mdi mdi-database",
                "title": "Multi-Cloud Provider Consolidation",
                "content": "Manage multiple cloud providers from one consolidated dashboard.  Saving you time and effort in your day-to-day operations.",
            }, {
                "icon": "mdi mdi-palette",
                "title": "One-to-Many Aggregation",
                "content": "Multiple accounts are monitored and managed via a smart dashboard with hundreds of inbuilt rules.",
            }, {
                "icon": "mdi mdi-finance",
                "title": "Security and compliance Unified",
                "content": "PrymeCloud provides a unified view of your organisation's security vulnerabilities and compliance non-conformities through a single pane of glass.",
            }, {
                "icon": "mdi mdi-yin-yang",
                "title": "Setup in less than 2 minutes",
                "content": "Integrate PrymeCloud with all your existing cloud accounts in a seamless and frictionless manner.  No DevOps, no agents required. Azure Security and AWS Security made easy.",
            }, {
                "icon": "mdi mdi-apple-keyboard-command",
                "title": "Unlimited Users.  Unlimited Assets.",
                "content": "Our pricing model is designed with organisations of all sizes in mind.  No hidden charges for additional users and assets.  Pay only for the connected accounts.",
            }, {
                "icon": "mdi mdi-hexagon-multiple",
                "title": "Scalable In Real-time.",
                "content": "PrymeCloud is your cloud security partner throughout your journey from startup to enterprise level.  As your organisation grows additional accounts can be integrated into your existing tenant.  Cloud computing security like no other.",
            },{
                "icon": "mdi mdi-chemical-weapon",
                "title": "For Engineers And Management Alike.",
                "content": "Assess the robustness of your cloud environments, track progress and determine security levels in an intuitive user experience.  Ideal for power users and non-technical business managers alike.  The vulnerability management tool to achieve AWS compliance and adhere to Azure best practices.",
            },{
                "icon": "mdi mdi-crop-square",
                "title": "Works Perfectly Out Of The Box",
                "content": "PrymeCloud provides a fully-managed out-of-the box cloud security solution.  No infrastructure management is required.  The rules engine is equipped with pre-loaded rules.  Ideal for IaaS, PaaS, SaaS cloud computing security.",
            },
            ]
        };
    }

    render() {

        return (
            <React.Fragment>
                <section className="section" id="benefits">
                    <div className="container">
                        <Row>
                            <Col lg="12">
                                <div className="title-heading mb-5">
                                    <h3 className="text-dark mb-1 font-weight-light text-uppercase">Benefits</h3>
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            {this.state.ServiceList.map((service, index) => {
                                return <Col lg="4" md="6" key={index}>
                                    <div className="service-box rounded mt-4 p-4">
                                        <div className="service-icon mb-3">
                                            <i className={service.icon}></i>
                                        </div>
                                        <div className="services-desc">
                                            <div className="service-title mb-2 position-relative">
                                                <h5 className="font-weight-normal mb-3"><Link to="#" className="text-dark">{service.title}</Link></h5>
                                            </div>
                                            <p className="text-muted mb-3 f-14">{service.content}</p>
                                            <p className="mb-0 text-uppercase f-13"><Link to="/" className="text-primary">Get Started<i className="mdi mdi-arrow-right ml-2"></i></Link></p>
                                        </div>
                                    </div>
                                </Col>
                            })}
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default ServiceSection;